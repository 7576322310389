.map-percentage-chart {
  width: 100%;
  aspect-ratio: 16/9;
}

@media only screen and (min-width: $m-bp) {
  .map-percentage-chart {
    width: 50%;
  }
}
