.clock-chart {
  position: relative;
  width: fit-content;
}

.clock-hands {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .hour-hand {
    position: absolute;
    top: 40%;
    left: 49%;
    width: 6px;
    height: 46px;
    background-color: $brand-purple-15;
    border-radius: 16px;
  }
  .mins-hand {
    position: absolute;
    top: 34%;
    left: 49%;
    width: 6px;
    height: 74px;
    background-color: $brand-purple-15;
    border-radius: 16px;
  }
}
