.tabs-button-group-mobile {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 24px 0px 24px;
  align-items: center;
  color: white;
  border: 1px solid transparent;
  background: $card-purple;

  overflow: hidden;
  z-index: 20;

  .tabs-group-dropdown-btn {
    display: flex;
    justify-content: space-between;
    color: white;
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 8px 0;
    border-bottom: 1px solid white;
    transition: border-color 0.3s;
    @include arrow-down-after();

    &:last-child {
      border-bottom: none;
    }
  }

  .tabs-btn-dropdown-list {
    top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin: 0;
    padding: 0;
    align-self: stretch;
    list-style: none;
    color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s;
    opacity: 0;

    li {
      width: 100%;
    }
    .tabs-category-item-btn {
      text-align: left;
      width: 100%;
      color: white;
      border-bottom: 1px solid white;
      padding-bottom: 24px;
    }
  }

  &.expanded {
    padding: 12px 24px 34px 24px;
    border-radius: 0px 0px 24px 24px;

    background:
      linear-gradient($card-purple, $card-purple) padding-box,
      linear-gradient(to bottom, $card-purple, white) border-box;

    .tabs-group-dropdown-btn {
      border-color: transparent;
      &::after {
        transform: rotate(-135deg);
      }
    }
    .tabs-btn-dropdown-list {
      opacity: 1;
      padding-top: 24px;
      max-height: 1000px;
    }
  }
}

.tabs-button-group-desktop {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .tabs-btn {
    color: white;
    opacity: 0.5;
    padding: 4px 0;
    transition: all 0.3s;
    &.selected {
      opacity: 1;
    }

    p {
      text-align: center;
      max-width: 90%;
      margin: 0 auto;
    }
  }

  .tabs-selected-highlite-bar {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    transition: all 0.5s;
    &::after {
      content: '';
      display: block;
      width: 64px;
      height: 2px;
      margin: 0 auto;
      margin-top: 8px;
      background-color: $brand-honey;
    }
  }
}
