#category-nav {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: $midnight-purple;
  display: block;
  flex-direction: row;
  align-items: center;

  #nav-item-wrapper {
    width: fit-content;
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: center;
    column-gap: clamp(0px, 2.5vw, 52px);
  }

  .nav-item {
    color: white;
    padding: 8px 12px;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 70px;
    border-bottom: 4px solid transparent;

    &:visited {
      color: white;
      text-decoration: none;
    }

    &.selected {
      border-bottom: 4px solid $brand-honey;
    }
    &:hover {
      color: $brand-honey;
    }
  }
}

#category-nav-mobile {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 999;

  #mobile-nav-bg-cover {
    position: absolute;
    width: 100vw;
    background-color: transparent;
    transition: all 0.3s;
    height: 0;
    padding: 24px 20px;
  }

  #mobile-nav-wrapper {
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;
    border-radius: 32px;
    overflow: hidden;
    gap: 0;
    color: white;
    border: 1px solid var(--Dark-Purple, #271741);
    background: var(--Purple---Int-100, #622fb4);
    box-shadow:
      0px 5px 10px 0px rgba(0, 0, 0, 0.1),
      0px 18px 18px 0px rgba(0, 0, 0, 0.09),
      0px 41px 25px 0px rgba(0, 0, 0, 0.05),
      0px 73px 29px 0px rgba(0, 0, 0, 0.01),
      0px 114px 32px 0px rgba(0, 0, 0, 0);
  }

  #main-nav-choice-btn {
    width: 100%;
    padding: 12px 24px;
    height: 70px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include arrow-down-after();
  }

  #mobile-nav-item-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: center;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    gap: 24px;
    opacity: 0;
    transition: all 0.3s;
    li {
      text-align: left;
      padding: 0px 24px;
    }

    .nav-item {
      display: block;
      width: 100%;
      text-align: left;
      transition: color 0.3s;
      &:hover {
        color: $brand-honey;
      }
    }

    #mobile-nav-footer {
      background: white;
      padding: 16px 24px 24px 24px;
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .small-athena-logo {
        width: 22px;
        height: 22px;
        object-fit: contain;
        margin-bottom: 8px;
      }

      .description {
        color: $midnight-purple;
        font-weight: 600;
        margin-bottom: 4px;
      }
      .athenahealth-link {
        cursor: pointer;
        color: $brand-purple-100;
        text-decoration: underline;
      }
    }
  }
  &.expanded {
    #mobile-nav-bg-cover {
      height: 100vh;
      background-color: rgba(22, 15, 65, 0.9);
    }
    #mobile-nav-item-list {
      max-height: 1000px;
      margin-top: 48px;
      opacity: 1;
    }
  }
}
