.horizontal-percentage-chart {
  border-radius: 40px;
  width: '100%';
  height: 15px;
  background: $gray-50;
  overflow: hidden;
}

.horizontal-percentage-inner {
  width: 20%;
  height: inherit;
  border-radius: 32px;
  background-color: $brand-purple-50;
}
