.circle-counter-chart {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  row-gap: 12px;
  margin: 0 auto;
  width: 100%;
  margin: 0 auto;
}

.circle-counter-row {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}

.quarter-card {
  .grid-dots {
    min-width: 12px;
    min-height: 12px;
    max-width: 32px;
    max-height: 32px;
  }
}

.grid-dots {
  position: relative;
  flex-shrink: 0;
  display: block;
  border-radius: 50%;
  background-color: #6d6e6f;
  min-width: 12px;
  min-height: 12px;
  max-width: 32px;
  max-height: 32px;

  z-index: 1;
  &.highlite {
    z-index: 0;
    content: '';
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: $m-bp) {
  .grid-dots {
    min-width: 20px;
    min-height: 20px;
  }
}
