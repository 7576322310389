@import 'global.scss';
@import 'template/hero.scss';
@import 'template/navigation.scss';
@import 'template/sectionsStyle.scss';
@import 'molecules/statCards.scss';
@import 'molecules/doubleStatCards.scss';
@import 'molecules/videoCards.scss';
@import 'molecules/interactionCards.scss';
@import 'molecules/fluTrendMap.scss';
@import 'molecules/diagnosesLineChart.scss';
@import 'atoms/circleCounterChart.scss';
@import 'atoms/singleCircleChart.scss';
@import 'atoms/HorizontalPercentageBarChart.scss';
@import 'atoms/clock.scss';
@import 'atoms/mapPercentageChart.scss';
@import 'atoms/tabs.scss';
@import 'atoms/verticalBarChart.scss';
@import 'atoms/slider.scss';
@import 'atoms/horizontalBarChart.scss';
@import 'atoms/singlePieChart.scss';

.App {
  text-align: center;
}

.bleed-section {
  max-width: none;
}

section {
  max-width: 1440px;
  margin: 0 auto;
}

.container {
  position: relative;
  max-width: $xl-bp;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.section-vertical-gap {
  padding-top: 128px;
  padding-bottom: 128px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: $xl-bp;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

h2 {
  font-size: clamp(2.875rem, 2.5066rem + 1.8421vw, 3.75rem);
  line-height: 100%;
}

.content-reveal-button {
  position: relative;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  border: none;
  border-radius: 56px;
  background: $brand-honey;
  margin-left: auto;
  margin-right: 0;
  transition: all 0.3s;
  z-index: 20;
  margin-top: 32px;

  @include close-icon();

  &.revealed {
    background-color: white;
    transform: rotate(45deg);
  }
}

.revealed-content-wrapper {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transition: all 0.4s;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
}

.share-btn {
  z-index: 20;
  width: 32px;
  height: 32px;
  background-image: url('../../public/assets/svg/share-btn-icon.svg');
}

@media (prefers-reduced-motion: no-preference) {
}

/* Small devices (portrait tablets and large phones, 480px and up) */
@media only screen and (min-width: $s-bp) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: $m-bp) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: $l-bp) {
}

/* Extra large devices (large laptops and desktops, 1280px and up) */
@media only screen and (min-width: $xl-bp) {
  .container,
  .flex-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}
