.diagnoses-line-chart {
  width: 100%;
  position: relative;

  .diagnoses-line-chart-container {
    width: 100%;
    position: relative;
    margin-top: 100px;
    padding-bottom: 10px;
    min-width: 620px;
  }

  .diagnoses-line-chart-image {
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    opacity: 0;
    transition: all 0.3s;

    &:first-child {
      position: relative;
      opacity: 1;
    }
  }
}

@media only screen and (min-width: $m-bp) {
  .diagnoses-line-chart {
    .diagnoses-line-chart-container {
      margin-top: 70px;
    }
  }
}
