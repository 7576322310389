.card-group-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  row-gap: 32px;
  column-gap: 2%;
  margin-top: 16px;
}

.card-basic {
  position: relative;
  border-radius: 24px;
  background: $card-purple;
  display: flex;
  overflow: hidden;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  text-align: left;

  h4,
  p {
    color: white;
  }

  .value-label {
    font-size: 5em;
    font-weight: 700;
    letter-spacing: -3px;
    line-height: 107%;
  }
  .unit-label {
    font-size: 2.875rem;
    font-weight: 700;
    line-height: 100%;
  }
  .description-label {
    // font-size: 21px;
    font-weight: 600;
    line-height: 120%;
    min-height: 76px;
    color: white !important;
  }
}

.card-eyebrow {
  font-weight: 600;
  position: relative;
}

.correct-icon-after::after {
  position: absolute;
  display: inline-block;
  content: '';
  margin-left: 4px;
  top: -4px;
  width: 24px;
  height: 24px;
  background: url('../../../public/assets/svg/correct-icon.svg');
}
.wrong-icon-after::after {
  position: absolute;
  display: inline-block;
  content: '';
  margin-left: 4px;
  width: 24px;
  height: 24px;
  background: url('../../../public/assets/svg/wrong-icon.svg');
}

.card-content-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 1;
}

.card-content-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: flex-start !important;
}
.overflow-x-card {
  overflow-x: scroll;
}
.overflow-x-card::-webkit-scrollbar {
  height: 4px;
}

/* Track */
.overflow-x-card::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.overflow-x-card::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

/* Handle on hover */
.overflow-x-card::-webkit-scrollbar-thumb:hover {
  background: black;
}

.card-icon {
  width: 48px;
  height: 48px;
}

.quarter-card {
  .card-icon {
    width: 32px;
    height: 32px;
  }
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-full-card {
  width: 100% !important;
}
.half-card {
  width: 100%;
}
.one-third-card {
  width: 100%;
  .value-label {
    font-size: 4rem !important;
  }
}

.clock-title-group {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
  align-items: baseline;
}

.clock-rich-txt {
  color: white !important;
  .value-label {
    font-size: 3.75rem;
  }
  .clock-unit-label {
    font-size: 1.938rem;
    font-weight: 700;
    line-height: 100%;
    color: white !important;
  }
}

@media only screen and (min-width: $s-bp) {
  .half-card {
    width: 49%;
  }
}

@media only screen and (min-width: $m-bp) {
  .card-group-container {
    flex-flow: row wrap;
  }

  .card-content-wrapper {
    padding: 32px 24px;
  }

  .card-basic {
    .value-label {
      font-size: clamp(3.75rem, 1vw, 5rem);
    }
    .unit-label {
      font-size: 2.875rem;
    }
  }

  .clock-rich-txt {
    .value-label {
      font-size: 5rem;
    }
    .clock-unit-label {
      font-size: 2.875rem;
    }
  }

  .one-third-card {
    width: 32%;
    .value-label {
      font-size: 5rem !important;
    }
  }

  .half-card {
    width: 48%;
  }

  .fourty-card {
    width: 40%;
  }

  .sixty-card {
    width: 60%;
  }

  .seventy-five-card {
    width: 75%;
  }

  .full-card {
    width: 100%;
    .card-content-detail {
      margin: 0 auto;
      flex-direction: row;
      column-gap: 24px;
      justify-content: center;
      .content-description {
        order: 2;
        max-width: 60%;
      }
    }
  }
}

@media only screen and (min-width: $l-bp) {
  .quarter-card {
    width: 23%;
    .value-label {
      font-size: clamp(4rem, 8vw, 5rem) !important;
    }
  }

  .card-basic {
    .value-label {
      font-size: clamp(5rem, 8vw, 8rem);
    }
    .unit-label {
      font-size: 4rem;
    }
  }
}
