.stat-card-slider {
  width: 100%;
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 16px;
    border-radius: 24px;
    background-color: $gray-70;
    transition: opacity 0.2s;
  }

  .slider::-moz-range-track,
  .slider::-webkit-slider-runnable-track {
    background: #ccc;
    height: 16px;
    border-radius: 16px;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 7px solid white;
    background: $brand-honey;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 7px solid white;
    background: $brand-honey;
    cursor: pointer;
  }

  .slider::-moz-range-progress {
    width: 100%;
    height: 16px;
    border-radius: 16px;
    background: linear-gradient(to bottom, $brand-honey, $brand-honey) 100% 50%/100% 18px no-repeat transparent;
  }
}
