.single-pie-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: $m-bp) {
  .single-pie-wrapper {
    justify-content: flex-start;
  }
}
