#statement-section {
  color: white;
  text-align: left;
  font-weight: 700;
  row-gap: 16px;

  p {
    max-width: none;
    font-size: 1.563rem;
    line-height: 120%;
    span {
      color: $brand-honey;
    }
  }
}

.category-section {
  text-align: left;
  margin-bottom: 128px !important;
  scroll-margin-top: 108px;
  h2 {
    color: $brand-honey;
  }
}

.section-content-wrapper {
  text-align: left;
  color: white;
  margin-top: 48px;
  h3 {
    font-size: 1.563rem;
    line-height: 120%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: $m-bp) {
  #statement-section {
    text-align: center;
    p {
      margin: 0 auto;
      max-width: 90%;
    }
  }

  .category-section {
    scroll-margin-top: 72px;
  }
}
