.interaction-card {
  height: auto;

  .stat-card-container {
    position: absolute;
    height: 100%;

    .card-basic {
      height: 100%;
    }
  }
}

.interaction-card-content {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  border: 4px solid $brand-honey;
  background: var(--Purple---Int-100, #622fb4);
  z-index: 30;

  .interactive-card-content-wrapper {
    justify-content: flex-start;
    gap: 24px;
    min-height: 500px;
  }

  .quiz-question-label {
    color: $brand-honey;
  }

  .quiz-options-group {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    gap: 8px;
  }

  .quiz-options-group-short {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 8px;
  }

  .quiz-option {
    width: 100%;
    padding: 14px 16px;
    border-radius: 48px;
    border: 1px solid white;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: $brand-honey;
    transition: all 0.3s;
    font-size: 18px;
    font-weight: 600;

    &:hover {
      border: 1px solid $brand-honey;
      box-shadow: 0 0 0 1px $brand-honey;
    }

    &.selected {
      background-color: $purple-purple;
      border: 1px solid $brand-honey;
      box-shadow: 0 0 0 1px $brand-honey;
    }
  }

  .quiz-option-short {
    border-radius: 16px;
    font-size: 2rem;
    width: 47%;
    flex-shrink: 0;
    flex-direction: column;
    padding: 16px;
    gap: 12px;
    font-weight: 700;

    &.selected {
      box-shadow: 0 0 0 3px $brand-honey;
    }
  }

  .submit-btn {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    padding: 10px 25px;
    width: fit-content;
    flex-shrink: 0;
    border-radius: 30px;
    border: 2px solid #fff;

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

@media only screen and (min-width: $m-bp) {
  .interaction-card-content .quiz-option-short {
    font-size: 2.875rem;
  }
}
