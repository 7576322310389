.vertical-bar-wrapper {
  position: relative;
  width: 100%;
}

.vertical-bar-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 48px;
}

.tabs-vertical-bar-chart {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 620px;
  padding-top: 48px;
}

.simple-vertical-bar-chart {
  .value-label-wrapper,
  .vertical-bars-container {
    justify-content: center;
  }
}

.value-label-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.vertical-bars-container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 0px;
  row-gap: 0px;
  width: 100%;
  height: 250px;
  border-bottom: 1px solid $brand-purple-100;
}

.value-label-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 16px 0;
  width: 100%;

  .bar-label-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    min-width: 64px;
    max-width: 120px;
  }

  .bar-type-label {
    display: block;
    padding: 0 2px;
    margin: 0 auto;
    min-width: 64px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.vertical-bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  width: 100%;
  min-width: 64px;
  max-width: 120px;
  max-height: 100%;
  .bar-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 16px;
  }

  .bar-column {
    padding: 0 2px;
    width: 70%;
    text-align: center;
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(180deg, $brand-purple-100 0%, rgba(98, 47, 180, 0) 100%);
    transition: all 0.3s linear 0.2s;
  }
  .bar-value-label {
    margin: 8px 0;
  }
}
