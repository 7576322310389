.video-card-basic {
  position: relative;
  border-radius: 24px;
  background: $card-purple;
  overflow: hidden;
  text-align: left;
}

.card-content {
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: space-between;
}

.video-wrapper {
  background-color: black;
  padding: 0;
  margin: 0;
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
}
.video-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.video-card-detail {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .description-label {
    color: white !important;
  }
}
.half-card.video-right,
.video-right {
  .video-card-content-detail {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    .card-content {
      width: 100%;
      height: 50%;
    }

    .video-wrapper {
      width: 100%;
      height: 50%;
    }
  }
}
.half-card.video-left,
.video-left {
  .video-card-content-detail {
    display: flex;
    flex-direction: column-reverse;
    .card-content {
      width: 100%;
      height: 50%;
    }
    .video-wrapper {
      width: 100%;
      height: 50%;
    }
  }
  .video-content {
    order: 2;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: $m-bp) {
  .video-right {
    .video-card-content-detail {
      display: flex;
      flex-direction: row;
      .card-content {
        width: 35%;
        height: 100%;
      }
      .video-wrapper {
        width: 65%;
        height: 100%;
      }
    }
  }

  .video-left {
    .video-card-content-detail {
      display: flex;
      flex-direction: row-reverse;
      .card-content {
        width: 35%;
        height: 100%;
      }
      .video-wrapper {
        width: 65%;
        height: 100%;
      }
    }
    .video-content {
      order: 2;
    }
  }
}

@media only screen and (min-width: $l-bp) {
  .card-content {
    padding: 32px 24px;
  }
}
