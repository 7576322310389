.single-circular-chart {
  position: relative;
  width: 100%;
  min-width: 160px;
  display: flex;
  justify-content: flex-start;
}

.single-circle-chart-icon {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;

  object-fit: contain;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: $m-bp) {
  .single-circle-chart-icon {
    display: block;
    width: 50px;
    height: 50px;
  }
  .single-circular-chart {
    width: 40%;
    svg {
      margin: 0 auto;
    }
  }

  .full-card {
    .single-circular-chart {
      svg {
        max-width: 260px;
        max-height: 260px;
        margin: 0 auto;
      }
    }
  }
}
@media only screen and (min-width: $l-bp) {
  .single-circle-chart-icon {
    display: block;
    width: 70px;
    height: 70px;
  }
}
