// breakpoints
$s-bp: 480px;
$m-bp: 768px;
$l-bp: 992px;
$xl-bp: 1080px;

// colors
$brand-purple-100: #622fb4;
$brand-purple-70: #916dcb;
$brand-purple-50: #b097d9;
$brand-purple-30: #d0c1e9;
$brand-purple-15: #e7e0f4;
$card-purple: #271741;
$brand-honey: #f3a61c;
$brand-green: #0f8a07;
$purple-purple: #4e2d82;
$midnight-purple: #160f41;
$gray-70: #9a9b9b;
$gray-50: #6d6e6f80;

.bleed-section {
  max-width: none;
}

section {
  max-width: 1440px;
  margin: 0 auto;
}

.container {
  position: relative;
  max-width: $xl-bp;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  // border-left: 1px solid black;
  // border-right: 1px solid black;
}

.section-vertical-gap {
  padding-top: 128px;
  padding-bottom: 128px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: $xl-bp;
  margin: 0 auto;
  row-gap: 32px;
  column-gap: 32px;
  padding-left: 20px;
  padding-right: 20px;
}

.bg-purple-100-scheme {
  background-color: $brand-purple-100 !important;
}

.bg-purple-70-scheme {
  background-color: $brand-purple-70 !important;
}

.bg-purple-50-scheme {
  background-color: $brand-purple-50 !important;
}

.bg-honey-scheme {
  background-color: $brand-honey !important;
}

.color-purple-100-scheme {
  color: $brand-purple-100 !important;
  span {
    color: $brand-purple-100 !important;
  }
}

.color-purple-70-scheme {
  color: $brand-purple-70 !important;
  span {
    color: $brand-purple-70 !important;
  }
}

.color-purple-50-scheme {
  color: $brand-purple-50 !important;
  span {
    color: $brand-purple-50 !important;
  }
}

.color-honey-scheme {
  color: $brand-honey !important;
  span {
    color: $brand-honey !important;
  }
}

.xxl-title {
  font-size: clamp(3.75rem, 1vw, 5rem); //80
  font-weight: 700;
  line-height: 100%;
}
.xl-title {
  font-size: 3.75rem; //60
  font-weight: 700;
  line-height: 100%;
}
.l-title {
  font-size: 2.875rem; //46
  font-weight: 700;
  line-height: 100%;
}
.m-title {
  font-size: 2.25rem; //36
  font-weight: 700;
  line-height: 100%;
}
.sm-title {
  font-size: 1.938rem; //31
  font-weight: 700;
  line-height: 120%;
}
.xs-title {
  font-size: 1.563rem; //25
  font-weight: 700;
  line-height: 120%;
}

.xl-sub {
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 120%;
}

.l-sub {
  font-size: 1.313rem;
  font-weight: 600;
  line-height: 128%;
}
.m-sub {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 133%;
}
.sm-sub {
  font-size: 1rem;
  line-height: 120%;
}
.xs-sub {
  font-size: 0.875rem;
  line-height: 120%;
}

input {
  appearance: none;
  -webkit-appearance: none;
}

input,
label {
  cursor: pointer;
}

label,
small {
  margin-top: 0.1em;
}

.custom-radio {
  /* hiding browser el */
  appearance: none;
  /* Safari support */
  -webkit-appearance: none;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin-right: 0.5rem;

  &:checked {
    box-shadow: 0 0 0 1px white;
    border-color: $purple-purple;
    background-color: $brand-honey;
    border-width: 0.2rem;
  }

  &.custom-radio-short {
    height: 24px;
    width: 24px;

    &:checked {
      border-width: 0.313rem;
    }
  }
}

/* keyboard navigation focus appearance */
.custom-radio:focus-visible {
  outline-offset: 0;
}

@mixin arrow-right-after {
  &:after {
    content: '';
    position: relative;
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    stroke-linecap: round;
    border-radius: 1px;
    margin: 4px;
    transform: rotate(-45deg);
    transition: all 0.3s;
  }
}

@mixin arrow-down-after {
  &:after {
    content: '';
    position: relative;
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    stroke-linecap: round;
    border-radius: 1px;
    margin: 4px;
    transform: rotate(45deg);
    transition: all 0.3s;
  }
}

@mixin close-icon {
  &:before,
  &:after {
    position: relative;
    content: ' ';
    height: 14px;
    width: 2px;
    background-color: black;
  }
  &:before {
    margin: 0 7px;
    transform: rotate(0deg);
  }
  &:after {
    position: relative;
    margin-left: -17px;
    margin-right: 6px;
    transform: rotate(90deg);
  }
}
