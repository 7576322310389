#hero {
  position: relative;
  width: 100vw;
  max-height: 700px;
  overflow: hidden;
  box-shadow:
    0px 29px 63px 0px rgba(15, 15, 15, 0.15),
    0px 115px 115px 0px rgba(15, 15, 15, 0.13),
    0px 258px 155px 0px rgba(15, 15, 15, 0.08),
    0px 458px 183px 0px rgba(15, 15, 15, 0.02),
    0px 716px 201px 0px rgba(15, 15, 15, 0);
}

.hero-bg-video {
  z-index: -1;
  display: block;
  width: 100vw;
  aspect-ratio: 16/9;
}

.hero-heading-group {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.hero-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 19%;
  padding-left: 1rem;

  h1 {
    color: $brand-honey;
    padding: 0;
    margin: 0;
    display: inline;
    font-size: clamp(2rem, 8vw, 5rem);
    line-height: 90%;
    font-weight: 900;
    text-transform: uppercase;
  }

  .athena-logo {
    position: absolute;
    top: 36px;
    width: clamp(138px, 25vw, 210px);
    object-fit: contain;
    z-index: 3;
    pointer-events: none;
  }

  .shadow-txt-1 {
    position: relative;
    z-index: 2;
  }
  .shadow-txt-2 {
    position: relative;
    z-index: 1;
  }
}
