.flu-trend-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .flu-map-container {
    position: relative;
  }

  .flu-trend-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.3s;

    &:first-child {
      position: relative;
      opacity: 1;
    }
  }

  .flu-slider-wrapper {
    padding: 0 3%;
  }

  .indicator-label-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .flu-trend-indicator-btn {
    color: white;
    width: 60px;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 133%;
    transition: all 0.3s;

    &:before {
      content: '';
      display: block;
      border-radius: 1px;
      width: 2px;
      height: 16px;
      margin-left: 50%;
      margin-bottom: 4px;
      opacity: 0.5;
      background-color: #d9d9d9;
    }

    &.selected {
      color: $brand-honey;
    }
  }
}

@media only screen and (min-width: $m-bp) {
  .flu-trend-container {
    .flu-trend-indicator-label {
      font-size: 1.313rem;
      font-weight: 600;
      line-height: 128%;
    }
  }
}
