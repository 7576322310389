@import 'reset.scss';
@import 'global.scss';

body {
  margin: 0;
  font-family: 'source-sans-pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $brand-purple-100;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
