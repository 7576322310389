.horizontal-bar-chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  gap: 16px;
  margin-top: 24px;

  .horizontal-bar-item {
    width: 100%;
    display: flex;
    align-items: center;
    max-height: 64px;
    .inner-bar {
      display: flex;
      position: relative;
      gap: 0;
    }
    .pointy-arrow {
      top: 0;
      max-height: 64px;
      // margin-left: -7px;
    }
    .bar-label {
      margin-left: 16px;
    }
  }
}
