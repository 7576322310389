.double-stat {
  .double-stat-eyebrow {
    text-align: center;
    margin-bottom: 24px;
  }

  .card-content-detail {
    flex-flow: row wrap;
    column-gap: 2%;
    justify-content: space-between;
    align-items: center;
  }
  hr {
    border-color: $brand-purple-100;
    height: 1px;
    width: 100%;
  }
  .unit-label {
    color: white !important;
  }
}

.double-stat-item {
  width: 100%;
  .eyebrow {
    color: white !important;
  }

  &.center-txt {
    text-align: left;
  }

  .description-label {
    min-height: 0 !important;
  }
}

.double-stat-top-content {
  display: flex;
  flex-flow: row wrap;
  row-gap: 8px;
}

@media only screen and (min-width: $m-bp) {
  .double-stat {
    hr {
      width: 1px;
      height: 100%;
    }
  }
  .double-stat-item {
    width: 47%;
    &.center-txt {
      text-align: center;
    }
  }
  .double-stat-top-content {
    flex-flow: row nowrap;
    align-items: center;
  }
}
